import { BaseAPI, PostOptions } from './BaseAPI';
import {
  Campaign,
  CampaignAction,
  campaignActionToString,
  CampaignStatus,
  statusToString,
} from './interfaces/campaign';

export class CampaignAPI extends BaseAPI {
  network: string | null;
  baseUrl = 'https://6g5vuj5b15.execute-api.us-east-1.amazonaws.com/prod';

  constructor(network: string | null, jwtToken?: string) {
    super(jwtToken);
    this.network = network;
  }

  pathToUrl(path: string) {
    return `${this.baseUrl}${path}?stage=${this.network || 'mainnet'}`;
  }

  pathToUrlWithQueryParams(path: string) {
    return `${this.baseUrl}${path}&stage=${this.network || 'mainnet'}`;
  }

  fetch(path: string, options: RequestInit = {}) {
    return super.fetch(this.pathToUrl(path), options);
  }

  fetchWithQueryParams(path: string, options: RequestInit = {}) {
    return super.fetch(this.pathToUrlWithQueryParams(path), options);
  }

  fetchRaw(path: string, options: RequestInit = {}) {
    return super.fetchRaw(this.pathToUrl(path), options);
  }

  put(path: string, options: PostOptions = {}) {
    return super.put(this.pathToUrl(path), options);
  }

  post(path: string, options: PostOptions = {}) {
    return super.post(this.pathToUrl(path), options);
  }

  delete(path: string, options: RequestInit = {}) {
    return super.delete(this.pathToUrl(path), options);
  }

  async fetchAllCampaigns(action?: CampaignAction): Promise<Campaign[]> {
    const campaigns = (await this.fetchWithQueryParams(
      `/redeem/campaign/all?action=${action}&full=true`
    )) as Campaign[];

    return campaigns.map((campaign) => this.mapPropsToCampaign(campaign));
  }

  async scanQRCode(campaignId: number, data: string, deviceId: string, optionId?: number) {
    return await this.post(`/redeem/campaign/${campaignId}/code/scan`, {
      body: {
        code: data,
        optionId: optionId,
        deviceId: deviceId,
      },
    });
  }

  mapPropsToCampaign(campaign: Campaign): Campaign {
    return {
      ...campaign,
      status: getCampaignStatus(campaign),
      statusString: getCampaignStatusString(campaign),
      actionString: campaignActionToString(campaign.action),
      tokenData: {
        ...campaign.tokenData,
        tokenAttributes: campaign.tokenData.tokenAttributes.map((token) => ({
          ...token,
          filterType: 'attributes',
        })),
        tokens: campaign.tokenData.tokens.map((token) => ({
          ...token,
          filterType: 'no_filter',
        })),
        tokenIds: campaign.tokenData.tokenIds.map((token) => ({
          ...token,
          filterType: 'id',
        })),
        tokenRanges: campaign.tokenData.tokenRanges.map((token) => ({
          ...token,
          filterType: 'id_range',
        })),
      },
    };
  }
}

const getCampaignStatusString = (campaign: Campaign) => {
  // NOTE: for scanning - we don't care about the campaign start/end
  // Inactive campaigns (deleted, etc) consider to be completed.
  if (!campaign.active) {
    return statusToString(CampaignStatus.COMPLETED);
  }

  return statusToString(CampaignStatus.ACTIVE);
};

const getCampaignStatus = (campaign: Campaign) => {
  const now = Date.now();

  if (campaign.endDate && campaign.endDate < now) {
    return CampaignStatus.COMPLETED;
  } else if (campaign.startDate! > now) {
    return CampaignStatus.SCHEDULED;
  }

  return CampaignStatus.ACTIVE;
};
