import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CognitoUser } from 'amazon-cognito-identity-js';
import * as React from 'react';
import { useState } from 'react';
import { useCognito } from '../../hooks/useCognito';
import { formatUsername } from '../../utils/formatUsername';
import { Copyright } from '../common/Copyright';
import { ConfirmReset } from './ConfirmReset';

const theme = createTheme();

export const Reset = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [userName, setUserName] = useState('');
  const { userPool } = useCognito();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const email = data.get('email')!.toString();
    const username = formatUsername(email);

    const dataUserName = {
      Username: username,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(dataUserName);
    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        setShowConfirm(true);
        setUserName(cognitoUser.getUsername());
      },
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
      },
      //Optional automatic callback
      // inputVerificationCode: function(data) {
      //   var code = document.getElementById('code').value;
      //   var newPassword = document.getElementById('new_password').value;
      //   cognitoUser.confirmPassword(verificationCode, newPassword, {
      //     onSuccess() {
      //     },
      //     onFailure(err) {
      //     },
      //   });
      // },
    });
  };

  if (showConfirm) {
    return <ConfirmReset userPool={userPool} userName={userName} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              Reset
            </Button>
            <Grid container>
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};
