import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { CampaignView } from './CampaignView';

export const CampaignPage = () => {
  const match = useRouteMatch();
  const location = useLocation();
  console.log(location);

  return (
    <Switch>
      <Route path={`${match.path}/:id`}>
        <CampaignView />
      </Route>
      <Route>
        {/* by default, redirect to the main page */}
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};
