import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useCampaignAPI } from '../../hooks/useCampaignAPI';
import { useCognito } from '../../hooks/useCognito';
import { useDeviceId } from '../../hooks/useDeviceId';
import { Campaign, CampaignAction } from '../../utils/interfaces/campaign';
import { CampaignSection } from './CampaignSection';

export const AllCampaigns = () => {
  const { setDeviceId } = useDeviceId();
  const { userPool, setCognitoSession } = useCognito();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState<Boolean>(true);

  const { campaignAPI } = useCampaignAPI();

  useEffect(() => {
    const wrapper = async () => {
      try {
        const resAllJson = await campaignAPI.fetchAllCampaigns(CampaignAction.CODE_WITH_QUESTIONS);
        setCampaigns(resAllJson);
        setLoading(false);
      } catch (e) {
        // Request to fetchAllCampaigns fails if the JWT is stale.
        // In this case, sign out the user.
        console.log(
          '🔴 Error featching all campaigns in AllCampaigns.tsx -- likely the JWT is stale',
          e
        );
        logoutUser();
      }
    };
    wrapper();

    // also set the device uuid
    setDeviceId(uuidv4());
    // eslint-disable-next-line
  }, []);

  /**
   * Logic for Logout Button. Calls signOut on the cognitoUser and
   * and sets the cognito session to null. In App.tsx there is a useEffect
   * that listens for changes to the cognitoSession and once it gets
   * set to null, it will redirect to the login page.
   */
  const logoutUser = async () => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.signOut(() => {
        setCognitoSession(null);
      });
    }
  };

  return (
    <Grid container spacing={1} p={3}>
      <Grid item xs={12}>
        <Button variant="outlined" size="small" onClick={logoutUser}>
          Log out
        </Button>
        <br />
        <br />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h3">Your Campaigns</Typography>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        sx={{ display: 'inline-grid' }}
        p={2}
      >
        {loading ? (
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
        ) : (
          campaigns
            .filter((c) => c.statusString === 'Active')
            .map((campaign: Campaign) => <CampaignSection campaign={campaign} key={campaign.id} />)
        )}
      </Grid>
    </Grid>
  );
};
