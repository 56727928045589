import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import { AllCampaigns } from './components/campaigns/AllCampaigns';
import { CampaignPage } from './components/campaigns/CampaignPage';
import { DrawerHeader } from './components/common/DrawerHeader';
import { Reset } from './components/login/Reset';
import { SignIn } from './components/login/SignIn';
import { SignUp } from './components/login/SignUp';
import { useCognito } from './hooks/useCognito';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  ...(true && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

function App() {
  const [showSignIn, setShowSignIn] = useState(true);
  const { cognitoSession, userPool } = useCognito();

  useEffect(() => {
    if (cognitoSession) {
      setShowSignIn(false);
    } else {
      setShowSignIn(true);
    }
  }, [cognitoSession]);

  const postSignInCallback = () => {
    setShowSignIn(false);
  };

  return (
    <Switch>
      <Route path="/" exact>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Main>
            <DrawerHeader />
            {showSignIn ? (
              <SignIn postSignInCallback={postSignInCallback} setShowSignIn={setShowSignIn} />
            ) : (
              <Redirect to="/campaigns" />
            )}
          </Main>
        </Box>
      </Route>
      <Route path="/register" exact>
        <SignUp userPool={userPool} />
      </Route>
      <Route path="/reset" exact>
        <Reset />
      </Route>
      <Route path="/campaign">{showSignIn ? <Redirect to="/" /> : <CampaignPage />}</Route>
      <Route path="/campaigns" exact>
        {showSignIn ? <Redirect to="/" /> : <AllCampaigns />}
      </Route>
    </Switch>
  );
}

export default App;
