import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Campaign, CodeOption } from '../../utils/interfaces/campaign';

interface CampaignSectionProps {
  campaign: Campaign;
}

interface CampaignCardProps {
  option?: CodeOption;
  onClick: (option?: CodeOption) => void;
}

const CampaignCard = ({ option, onClick }: CampaignCardProps): JSX.Element => {
  const clickHandler = () => {
    onClick(option);
  };
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          {option?.description ?? 'Default option'}
        </Typography>
        <br />
      </CardContent>
      <CardActions>
        <Button size="small" onClick={clickHandler}>
          Open Scanner
        </Button>
      </CardActions>
    </Card>
  );
};

export const CampaignSection = ({ campaign }: CampaignSectionProps): JSX.Element => {
  const history = useHistory();
  const { name, id, options } = campaign;
  const hasOptions = options && options.length;
  const onSelected = (option?: CodeOption) => {
    // null/undefined => campaign has no options
    console.log('cid:', id, '; option selected:', option ?? false);
    history.push(`/campaign/${id}${option != null ? '?option=' + option.id : ''}`, {
      campaign,
      option,
    });
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography variant="h5" color="text.secondary" gutterBottom>
          {name} - {id}
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        {hasOptions ? (
          options.map((option: CodeOption) => {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={`${id}-${option.id}`}>
                <CampaignCard option={option} onClick={onSelected} />
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <CampaignCard onClick={onSelected} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
