import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { useHistory, useLocation } from 'react-router-dom';
import { useCampaignAPI } from '../../hooks/useCampaignAPI';
import { useCognito } from '../../hooks/useCognito';
import { useDeviceId } from '../../hooks/useDeviceId';
import { Campaign, CodeOption } from '../../utils/interfaces/campaign';
import { InfoView } from './components/InfoView';

const theme = createTheme();

interface CampaignRouteState {
  campaign: Campaign;
  option: CodeOption;
}

const findTimeSinceScanned = (errMessage: string) => {
  const dateString = errMessage.split('--')[1];
  const scannedDate: Date = new Date(dateString);

  const curDate: Date = new Date();
  // console.log('diffrences based on milliseconds', curDate.getTime() - scannedDate.getTime());
  const minutesFromNowAndScan = (curDate.getTime() - scannedDate.getTime()) / (60 * 1000);
  console.log('diffrences based on minutes', minutesFromNowAndScan);

  return minutesFromNowAndScan;
};

export const CampaignView = () => {
  const { campaignAPI } = useCampaignAPI();
  const { userPool, setCognitoSession } = useCognito();
  const [data, setData] = useState('');
  const [allowed, setAllowed] = useState(false);
  const [hasReqd, setHasReqd] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [codeAlreadyScanned, setCodeAlreadyScanned] = useState(false);
  const [codeRecentlyScanned, setCodeRecentlyScanned] = useState(false);
  const [somethingWentWrong, setSomethingWentWrong] = useState('');
  const [showInfo, setShowInfo] = useState(false);
  const [redemptionData, setRedemptionData] = useState({});
  const history = useHistory();
  const location = useLocation<CampaignRouteState>();
  const { deviceId } = useDeviceId();
  /** to avoid race condition between data state updated and trying to read the code */
  let scanning = false;

  const scanAgain = () => {
    setShowInfo(false);
    setCodeAlreadyScanned(false);
    setCodeRecentlyScanned(false);
    setInvalidCode(false);
    setHasReqd(false);
    setAllowed(false);
    setSomethingWentWrong('');
    setData('');
    setRedemptionData({});
  };

  const goToCampaigns = useCallback(() => {
    history.push('/campaigns');
  }, [history]);

  if (!location.state || !location.state.campaign) {
    console.warn('No campaign passed, wrong navigation?');
    history.push('/campaigns');
    return null;
  }

  const option = location.state.option;
  const campaignInfo = location.state.campaign;
  const campaignId = location.state.campaign.id;

  const validateScannedData = async (data: string) => {
    scanning = true;
    if (data) {
      // Send data to backend to validate code.
      const decodedData = data.replace('#print', '');
      try {
        console.log(`~~~ QRCode to mark as seen: ${data}`);
        // TEMP: remove '#print' if that was scanned (should also remove from front end)

        // Format will be like this: asdlakjsdlkjqaslkd?option=15
        if (decodedData.includes('option')) {
          const splitData = decodedData.split('?option=');
          if (option?.id !== parseInt(splitData[1])) {
            console.warn('Scanned option id does not match current scanner');
            setInvalidCode(true);
            setSomethingWentWrong("Scanned option id does not match current scanner'");
            setAllowed(false);
            setHasReqd(true);
            setShowInfo(true);
            scanning = false;
            return;
          }
          const res = await campaignAPI.scanQRCode(
            campaignId,
            splitData[0],
            deviceId,
            parseInt(splitData[1])
          );
          console.log('!1 res from campaignAPI.scanQRCode()...: ', res);
          setRedemptionData(res);
        } else {
          const res = await campaignAPI.scanQRCode(campaignId, decodedData, deviceId, option?.id);
          console.log('!2 res from campaignAPI.scanQRCode()...: ', res);
          setRedemptionData(res);
        }
        setHasReqd(true);
        setAllowed(true);
      } catch (e) {
        // @ts-ignore
        console.log('XX e.message: ', e.message);
        // @ts-ignore
        if (e.message.includes('Invalid code')) {
          setInvalidCode(true);
          // @ts-ignore
        } else if (e.message.includes('Code already scanned')) {
          // @ts-ignore
          const minutesSinceScanned = findTimeSinceScanned(e.message);
          console.log('minutesSinceScanned: ', minutesSinceScanned);

          if (minutesSinceScanned < 5) setCodeRecentlyScanned(true);
          else setCodeAlreadyScanned(true);
        } else {
          console.log(
            '❌ Error: JWT is stale so request to campaignAPI unauthorized. Signing you out now.'
          );

          // setSomethingWentWrong('JWT is stale - Sign out and sign back in to refresh your session');

          // Sign the user out if we get here. Do this by setting the cognito session to null.
          // In App.tsx there is a useEffect that listens for changes to the cognitoSession
          // and once it gets set to null, it will redirect to the login page.
          const cognitoUser = userPool.getCurrentUser();

          if (cognitoUser) {
            cognitoUser.signOut(() => {
              setCognitoSession(null);
            });
          }
        }
        setHasReqd(true);
        setAllowed(false);
      } finally {
        scanning = false;
      }
      setShowInfo(true);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          size="small"
          onClick={goToCampaigns}
        >
          back
        </Button>
        <h2>Scan the QRCode Below</h2>
        <CssBaseline />
        {!showInfo && (
          <QrReader
            onResult={(result, error) => {
              if (!!result) {
                if (data === '' && !scanning) {
                  setData(result.getText());
                  validateScannedData(result.getText());
                }
              }

              if (!!error) {
                console.info(error);
              }
            }}
            constraints={{
              facingMode: 'environment',
            }}
            videoContainerStyle={{
              width: '100%',
              outlineColor: hasReqd ? (allowed ? 'green' : 'red') : 'black',
              outlineWidth: '10px',
              outlineStyle: 'solid',
            }}
          />
        )}
        {showInfo && (
          // @ts-ignore
          <InfoView
            invalidCode={invalidCode}
            codeRecentlyScanned={codeRecentlyScanned}
            codeAlreadyScanned={codeAlreadyScanned}
            somethingWentWrong={somethingWentWrong}
            allowed={allowed}
            redemptionData={redemptionData}
            scanAgain={scanAgain}
          />
        )}
      </Container>
      <br />
      <br />
      <Container>
        <h3>Campaign Info</h3>
        <p>
          Campaign: {campaignInfo.name}
          {option && ` - ${option.description}`}
        </p>
        <p>
          Start Date:{' '}
          {campaignInfo.startDate ? new Date(campaignInfo.startDate).toString() : 'Unspecified'}
        </p>
        <p>
          End Date:{' '}
          {campaignInfo.endDate ? new Date(campaignInfo.endDate).toString() : 'No End Date'}
        </p>
      </Container>
    </ThemeProvider>
  );
};
