import React from 'react';

interface DeviceIdData {
  deviceId: string;
  setDeviceId: (id: string) => void;
}

export const DeviceIdContext = React.createContext<DeviceIdData>({
  deviceId: '',
  setDeviceId: (id: string) => {
    return;
  },
});

export const DeviceIdProvider = ({ children }: { children: JSX.Element }) => {
  const [deviceId, setDeviceId] = React.useState('');

  return (
    <DeviceIdContext.Provider value={{ deviceId, setDeviceId }}>
      {children}
    </DeviceIdContext.Provider>
  );
};

export const useDeviceId = () => {
  return React.useContext(DeviceIdContext);
};
