import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MouseEventHandler } from 'react';

const theme = createTheme();
interface InfoViewProps {
  invalidCode: boolean;
  allowed: boolean;
  codeAlreadyScanned: boolean;
  codeRecentlyScanned: boolean;
  somethingWentWrong: string;
  redemptionData: any;
  scanAgain: MouseEventHandler<HTMLButtonElement>;
}

export const InfoView = ({
  invalidCode,
  allowed,
  codeAlreadyScanned,
  codeRecentlyScanned,
  somethingWentWrong,
  scanAgain,
  redemptionData,
}: InfoViewProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        {invalidCode && (
          <h1
            style={{
              marginLeft: 'auto',
              width: '60%',
              outlineColor: 'red',
              outlineWidth: '10px',
              outlineStyle: 'solid',
              textAlign: 'center',
            }}
          >
            Invalid Code
          </h1>
        )}
        {allowed && (
          <div>
            <h1
              style={{
                // marginLeft: 'auto',
                width: '60%',
                outlineColor: 'green',
                outlineWidth: '10px',
                outlineStyle: 'solid',
                textAlign: 'center',
              }}
            >
              🎉 Enjoy! 🎉
            </h1>
            <h3>Token Info:</h3>
            <p>{redemptionData.contractAddress}</p>
            <p>{redemptionData.nft.name}</p>
            <Box
              component="img"
              sx={{
                height: 233,
                width: 350,
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
              }}
              alt="NFT image associated with that QRCode."
              src={redemptionData.nft.image}
            />
            {/* //  */}
          </div>
        )}
        {codeRecentlyScanned && (
          <h1
            style={{
              width: '60%',
              outlineColor: 'Yellow',
              outlineWidth: '10px',
              outlineStyle: 'solid',
              textAlign: 'center',
            }}
          >
            Code Scanned within the last minute
          </h1>
        )}
        {codeAlreadyScanned && (
          <h1
            style={{
              width: '60%',
              outlineColor: 'red',
              outlineWidth: '10px',
              outlineStyle: 'solid',
              textAlign: 'center',
            }}
          >
            Code Already Scanned
          </h1>
        )}
        {somethingWentWrong && (
          <h1
            style={{
              width: '60%',
              outlineColor: 'black',
              outlineWidth: '10px',
              outlineStyle: 'solid',
              textAlign: 'center',
            }}
          >
            Error: {somethingWentWrong}
          </h1>
        )}
        <br />
        <Button
          onClick={scanAgain}
          style={{
            alignContent: 'center',
            textAlign: 'center',
          }}
          variant="contained"
        >
          Scan Again
        </Button>
      </Container>
    </ThemeProvider>
  );
};
