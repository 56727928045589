import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import { CognitoProvider } from './hooks/useCognito';
import { DeviceIdProvider } from './hooks/useDeviceId';
import { NetworkProvider } from './hooks/useNetwork';
import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV !== 'development') {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <Router basename={process.env.PUBLIC_URL}>
      <CognitoProvider>
        <NetworkProvider>
          <DeviceIdProvider>
            <App />
          </DeviceIdProvider>
        </NetworkProvider>
      </CognitoProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
